body {
  position: relative;
  &:after {
    content: "";
    position: absolute;
    left: 0;
    top: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0, 0, 0, .6);
    opacity: 0;
    pointer-events: none;
    transition: .33s cubic-bezier(0.46, 0.03, 0.52, 0.96);
    z-index: 9999;
  }
  &[data-offcanvas-state="shown"] {
    overflow-y: hidden;
    &:after {
      pointer-events: auto;
      opacity: 1;
    }
  }

  .offcanvas-element,
  .toggler {
    transition: .33s cubic-bezier(0.46, 0.03, 0.52, 0.96);
  }

  .offcanvas-element {
    position: fixed;
    width: 250px;
    top: 0;
    left: 0;
    right: 1px;
    height: 100vh;
    overflow: visible;
    box-shadow: 0px 0px 0px #000;
    transform: translateX(-100%);

    .toggler {
      position: absolute;
      text-indent: -9999999999999999999px;
      left: 100%;
      top: 0;
      width: 60px;
      height: 45px;
      margin: 15px;
      -webkit-transform: rotate(0deg) scale(.8);
      -moz-transform: rotate(0deg) scale(.8);
      -o-transform: rotate(0deg) scale(.8);
      transform: rotate(0deg) scale(.8);
      -webkit-transition: .5s ease-in-out;
      -moz-transition: .5s ease-in-out;
      -o-transition: .5s ease-in-out;
      transition: .5s ease-in-out;
      cursor: pointer;
      
      span {
        display: block;
        position: absolute;
        height: 9px;
        width: 100%;
        background: #005880;
        border-radius: 9px;
        opacity: 1;
        left: 0;
        -webkit-transform: rotate(0deg);
        -moz-transform: rotate(0deg);
        -o-transform: rotate(0deg);
        transform: rotate(0deg);
        -webkit-transition: .25s ease-in-out;
        -moz-transition: .25s ease-in-out;
        -o-transition: .25s ease-in-out;
        transition: .25s ease-in-out;
      }

      span:nth-child(1) {
        top: 0px;
        -webkit-transform-origin: left center;
        -moz-transform-origin: left center;
        -o-transform-origin: left center;
        transform-origin: left center;
      }

      span:nth-child(2) {
        top: 18px;
        -webkit-transform-origin: left center;
        -moz-transform-origin: left center;
        -o-transform-origin: left center;
        transform-origin: left center;
      }

      span:nth-child(3) {
        top: 36px;
        -webkit-transform-origin: left center;
        -moz-transform-origin: left center;
        -o-transform-origin: left center;
        transform-origin: left center;
      }

    }

    .content {
      height: 100%;
      width: 100%;
      overflow-x: hidden;
      overflow-y: auto;
      background-color: #222;
      padding: 1em;

      .section {
        padding: .2em 0;
        a {
          color: white;
        }
      }
    }

    &[aria-hidden="false"] {
      transform: translateX(0%);
      box-shadow: 0px 2px 6px #000;

      .toggler {
        span {
          background: #0097dc;
          &:nth-child(1) {
            -webkit-transform: rotate(45deg);
            -moz-transform: rotate(45deg);
            -o-transform: rotate(45deg);
            transform: rotate(45deg);
            top: -3px;
            left: 8px;
          }
          &:nth-child(2) {
            width: 0%;
            opacity: 0;
          }
          &:nth-child(3) {
            -webkit-transform: rotate(-45deg);
            -moz-transform: rotate(-45deg);
            -o-transform: rotate(-45deg);
            transform: rotate(-45deg);
            top: 39px;
            left: 8px;
          }
        }
      }

    }
  }
  
}