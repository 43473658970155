// Main footer classes
    #portal-footer-wrapper {
        .navbar-default {
            a {
                color:$gray-darker;
            }
            @media screen and (min-width: $grid-float-breakpoint) {
                li + li {
                    position: relative;
                    &:before {
                        content: "";
                        display: block;
                        height: 1em; width: 0.1rem;
                        background-color: $gray-darker;
                        left:0; top: 50%;
                        transform: translateY(-0.5em);
                        position: absolute;
                    }
                }
            }
        }
    }