#cookie-bar {
    background:$gray-lighter; 
    height:auto; line-height:24px; 
    color:$gray-darker;
    text-align:left; 
    margin: $padding-small-vertical; 
    padding: $padding-large-horizontal;
    font-size:$font-size-small;
 
}
#cookie-bar.fixed {
    position:fixed; top:0; left:0; 
    width:calc(100% - 3.2rem);
    z-index: $zindex-modal;
    max-width: 40em; //640px
    box-shadow: 0 0 2px rgba($gray-darker, .5);
}
#cookie-bar.fixed.bottom {
    bottom:0; top:auto;
}
#cookie-bar p {
    margin:0; padding:0;
}
#cookie-bar a {
    transition: color .25s ease;
    color:$brand-dark;
    display:inline;  text-decoration:none; 
    font-weight: $font-bold;
    &:hover {
        color: $brand-primary;
    }
}
#cookie-bar .cb-enable { // close
    margin-right: 1.0rem;
   
}
#cookie-bar .cb-enable:hover {
    // background:#009900;
}
#cookie-bar .cb-disable {
    // background:#990000;
}
#cookie-bar .cb-disable:hover {
    // background:#bb0000;
}
#cookie-bar .cb-policy {
    // background:#0033bb;
}
#cookie-bar .cb-policy:hover {
    // background:#0055dd;
}