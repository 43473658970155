// Search form and results

// Live results
// -------------------------
    .pat-livesearch .livesearch-results {
        z-index: $zindex-navbar + 1 !important;
        .results-summary {
            margin: 1em 0;
            color: $gray-light-medium;
        }
        li {
            padding: 0 1em;
        }
        h4 {
            margin-bottom: 0;
        }
    }

// Search form
// --------------------------
    #searchGadget_form {
        input.bg-gray-lighter {
            background-color: $gray-lighter;
            height: $input-height-base;
        }
    }

// Search results
// ----------------------------
    .searchResults {
        .result-title {
            @extend .h4;
        }
    }