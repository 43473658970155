// .template-summary_view
.template-summary_view {
    .tileItem {
        @extend .clearfix;
        padding-bottom: $padding-large-vertical;
        border-bottom: 1px solid $gray-lighter;
    }
    .tileHeadline {
        a {
            transition: color .25s ease;
            color: inherit;
            &:hover {
                color:  $link-hover-color;
            }
        }
    }
    .tileFooter {
        @extend .small;
        float: left;
        clear: none;    
    }
}