.template-blog {
    $imgHeight: 26.7rem;
    #content-core {
        @extend .row;
        display: flex;
        flex-wrap: wrap;
    }
    .tileItem {
      margin-bottom: 3rem;
    }
    .tileImage {
        float: none;
        height: $imgHeight;
        overflow: hidden;
        padding-right: 0;
        img {
            width: 100%; height: auto;
            display: block;
        }
    }
    .tileText {
        color: $gray;
        padding: 1.2rem 1.5rem 1.5rem 1.5rem;
        background-color: $gray-lighter;
       flex-grow: 1;
    }
    .item_link {
        display: block;
    }
    .bgimage {
        height: $imgHeight;
        a {
            display: block;
            height: $imgHeight;
            width: 100%;
        }
    }
    .documentByLine{
        font-size: 81%;
        color: $gray-light;
        &:before {
            content: '\e805';
            font-family: "fontello";
            font-style: normal;
            font-weight: normal;
            speak: none;
            display: inline-block;
            text-decoration: inherit;
            width: 1em;
            margin-right: .2em;
            text-align: center;
            font-variant: normal;
            text-transform: none;
            line-height: 1em;
            margin-left: .2em;
            -webkit-font-smoothing: antialiased;
        }
    }

    @media screen and (min-width: $screen-sm-min) {
        .tileItem {
            width: 33.33%;       
            &:nth-child(3n + 1) {
                clear: left;
            }
        }
    }
}