// Portlet calendar customitzations
    .portlet.portletCalendar {
        table .cal_has_events {
            font-weight: $font-bold;
            a {
                &:hover {

                }
                &:after {
                    content: none;
                    display: none;
                }
            }
        }
        td {
            &:hover {
                background-color: $gray-lighter;
            }
        }
        td.today {
            border-color: $gray-lighter;           
            background-color: $gray-lighter;
            > * {
                 background-color: $brand-primary;
                 color: $white;
                 box-shadow: 0 0 0 $padding-small-vertical $brand-primary;
            }
        }   
        
    }