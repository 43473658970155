// .template-listing_view
.template-listing_view {
    #content-core {
        ul.entries {
            list-style-type: disc;
            padding-left: 1.5em;
            .entry {
                display: list-item;
            }
        }
        header {
            @extend .h4;
            a {
                color: inherit;
                &:hover {
                    opacity: .8;
                }
            }
        }
    }
}