//
// Grid system
// --------------------------------------------------


// Equal height columns 
.eq-height {
    display: flex;
    flex-wrap: wrap;
    & > [class*='col-'], .eq-height article {
      display: flex;
      flex-direction: column;
  }
  .__center {
    justify-content: center;
  }
}

// Distributed columns quantity independent
.distributed {
  display: flex;
  flex-wrap: wrap;
  flex: 1;
  justify-content: space-around;
  &--140 > div {
    flex: 1 0 14.0rem;
  }

}

.display-flex {
  display: flex;
  &:before, &:after {
    content: none;
  }
}
.fg-1 { flex-grow: 1; }
.falign-center { align-items: center; }
.fjustify-center { justify-content: center;}