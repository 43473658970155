// Fix classes for plone - boostrap conflicts

body .plone-toolbar-left #edit-zone{
    z-index:$zindex-navbar-fixed;
}
@media screen and (min-width: $screen-sm-min) {
    body .plone-toolbar-left #edit-zone{
        z-index:$zindex-navbar-fixed;
    }
}
 