// .template-full_view

.template-full_view {
    #content-core {
        .item {
            border-bottom: 1px solid $gray-lighter;
            padding-bottom: $padding-large-vertical;
            .summary {
                a {
                    color: inherit;
                    &:hover {
                        opacity: .8;
                    }
                }
            }
        }
    }
}