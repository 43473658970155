// Document Actions: print, send, social share...

// Actions buttons
    .document-action {
        a {
            transition: color .25s ease;
            &:hover {
                color: $brand-light;
                text-decoration: none;
               i:before {
                   border-color: $brand-light;
                   color: $brand-light;
               }
            }
        }
        i {
            display: inline-block;
            &:before {
                font-family: "pictello";
                font-style: normal;
                font-weight: normal;
                speak: none;
                
                display: inline-block;
                text-decoration: inherit;
                width: 1em;
                margin-right: .2em;
                text-align: center;
                /* opacity: .8; */
                
                /* For safety - reset parent styles, that can break glyph codes*/
                font-variant: normal;
                text-transform: none;
                
                /* fix buttons height, for twitter bootstrap */
                line-height: 1em;
                
                /* Animation center compensation - margins should be symmetric */
                /* remove if not needed */
                margin-left: .2em;
                
                /* you can be more comfortable with increased icons size */
                /* font-size: 120%; */
                
                /* Font smoothing. That was taken from TWBS */
                -webkit-font-smoothing: antialiased;
                -moz-osx-font-smoothing: grayscale;

                border: 1px solid $gray-darker;
                color: $gray-darker;
                font-size: 2.0rem;
                line-height: 1em;
                width: 3.6rem; height: 3.6rem;
                padding: 0.8rem 0;
                text-align: center;
                border-radius: 50%;
                display: block;
            }
        }
    }
    #document-action-print {
        i:before {
           content: '\e82e';
        }        
    }
    #document-action-send {
        i:before {
            content: '\e81e';
        }
    }