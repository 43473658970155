// Margins and paddings
// Spacing utility classes inspired by Bootstrap 4
/*
    Where property is one of:

    m - for classes that set margin
    p - for classes that set padding
    Where sides is one of:

    t - for classes that set margin-top or padding-top
    b - for classes that set margin-bottom or padding-bottom
    l - for classes that set margin-left or padding-left
    r - for classes that set margin-right or padding-right
    x - for classes that set both *-left and *-right
    y - for classes that set both *-top and *-bottom
    blank - for classes that set a margin or padding on all 4 sides of the element
    Where size is one of:

    0 - for classes that eliminate the margin or padding by setting it to 0
    1 - (by default) for classes that set the margin or padding to 16px * .25 
    2 - (by default) for classes that set the margin or padding to 16px * .5
    3 - (by default) for classes that set the margin or padding to 16px    
    4 - (by default) for classes that set the margin or padding to 16px * 1.5
    5 - (by default) for classes that set the margin or padding to 16px * 3
    6 - (by default) for classes that set the margin or padding to 16px * 4.5
*/

// ==== Mixins
@mixin setPadding ($query: '', $amount: 0, $padding-value: 0) {
    .p#{$query}-#{$amount} {
        padding: $padding-value !important;
    }
    .pt#{$query}-#{$amount}, .py#{$query}-#{$amount} {
        padding-top: $padding-value !important;
    }
    .pb#{$query}-#{$amount}, .py#{$query}-#{$amount} {
        padding-bottom: $padding-value !important;
    }
    .pr#{$query}-#{$amount}, .px#{$query}-#{$amount} {
        padding-right: $padding-value !important;
    }
    .pl#{$query}-#{$amount}, .px#{$query}-#{$amount} {
        padding-left: $padding-value !important;
    }
}
@mixin setMargin ($query: '', $amount: 0, $margin-value: 0) {
    .m#{$query}-#{$amount} {
        margin: $margin-value !important;
    }
    .mt#{$query}-#{$amount}, .my#{$query}-#{$amount} {
        margin-top: $margin-value !important;
    }
    .mb#{$query}-#{$amount}, .my#{$query}-#{$amount} {
        margin-bottom: $margin-value !important;
    }
    .mr#{$query}-#{$amount}, .mx#{$query}-#{$amount} {
        margin-right: $margin-value !important;
    }
    .ml#{$query}-#{$amount}, .mx#{$query}-#{$amount} {
        margin-left: $margin-value !important;
    }
}

// ==== Screen independent  
    // 0px
    @include setPadding('', '0', 0);
    @include setMargin('', '0',0);
    // 2px
    @include setPadding('', '05', 0.2rem);
    @include setMargin('', '05',0.2rem);
    // 4px
    @include setPadding('', '1', 0.4rem);              // 16 * 0.25
    @include setMargin('', '1',0.4rem);
    // 8px
    @include setPadding('', '2', 0.6rem);              // 8 * 0.5
    @include setMargin('', '2', 0.6rem);
    // 16px
    @include setPadding('', '3', 1.4rem);              // 16
    @include setMargin('', '3', 1.4rem);
    // 24px
    @include setPadding('', '4', 2.4rem);              // 16 * 1.5
    @include setMargin('', '4', 2.4rem);
    // 48px
    @include setPadding('', '5', 4.8rem);              // 16 * 3
    @include setMargin('','5', 4.8rem);  
    // 72px
    @include setPadding('', '6', 7.2rem);              // 16 * 4.5
    @include setMargin('','6', 7.2rem);  
      
// ==== Extra small screen / phone
@media screen and (max-width: $screen-xs-max) {
    // 0px
    @include setPadding('-xs', '0', 0);
    @include setMargin('-xs', '0',0);
    // 2px
    @include setPadding('-xs', '05', 0.2rem);
    @include setMargin('-xs', '05',0.2rem);
    // 4px
    @include setPadding('-xs', '1', 0.4rem);              
    @include setMargin('-xs', '1',0.4rem);
    // 8px
    @include setPadding('-xs', '2', 0.6rem);              
    @include setMargin('-xs', '2', 0.6rem);
    // 16px
    @include setPadding('-xs', '3', 1.4rem);              
    @include setMargin('-xs', '3', 1.4rem);
    // 24px
    @include setPadding('-xs', '4', 2.4rem);              
    @include setMargin('-xs', '4', 2.4rem);
    // 48px
    @include setPadding('-xs', '5', 4.8rem);              
    @include setMargin('-xs','5', 4.8rem);
    // 72px
    @include setPadding('-xs', '6', 7.2rem);              // 16 * 4.5
    @include setMargin('-xs','6', 7.2rem);      
}
// ==== Small screen / tablet 
@media screen and (min-width: $screen-sm-min) {
    @include setPadding('-sm', '0', 0);
    @include setMargin('-sm', '0',0);
    // 2px
    @include setPadding('-sm', '05', 0.2rem);
    @include setMargin('-sm', '05',0.2rem);
    // 4px
    @include setPadding('-sm', '1', 0.4rem);              
    @include setMargin('-sm', '1',0.4rem);
    // 8px
    @include setPadding('-sm', '2', 0.6rem);              
    @include setMargin('-sm', '2', 0.6rem);
    // 16px
    @include setPadding('-sm', '3', 1.4rem);              
    @include setMargin('-sm', '3', 1.4rem);
    // 24px
    @include setPadding('-sm', '4', 2.4rem);              
    @include setMargin('-sm', '4', 2.4rem);
    // 48px
    @include setPadding('-sm', '5', 4.8rem);              
    @include setMargin('-sm','5', 4.8rem);
    // 72px
    @include setPadding('-sm', '6', 7.2rem);              // 16 * 4.5
    @include setMargin('-sm','6', 7.2rem);      
}
// ==== Medium screen / desktop
@media screen and (min-width: $screen-md-min) {
    @include setPadding('-md', '0', 0);
    @include setMargin('-md', '0',0);
    // 2px
    @include setPadding('-md', '05', 0.2rem);
    @include setMargin('-md', '05',0.2rem);
    // 4px
    @include setPadding('-md', '1', 0.4rem);              
    @include setMargin('-md', '1',0.4rem);
    // 8px
    @include setPadding('-md', '2', 0.6rem);              
    @include setMargin('-md', '2', 0.6rem);
    // 16px
    @include setPadding('-md', '3', 1.4rem);              
    @include setMargin('-md', '3', 1.4rem);
    // 24px
    @include setPadding('-md', '4', 2.4rem);              
    @include setMargin('-md', '4', 2.4rem);
    // 48px
    @include setPadding('-md', '5', 4.8rem);              
    @include setMargin('-md','5', 4.8rem);
    // 72px
    @include setPadding('-md', '6', 7.2rem);              // 16 * 4.5
    @include setMargin('-md','6', 7.2rem);      
}
// ==== Large screen / wide desktop    
 @media screen and (min-width: $screen-lg-min) {
    @include setPadding('-lg', '0', 0);
    @include setMargin('-lg', '0',0);
    // 2px
    @include setPadding('-lg', '05', 0.2rem);
    @include setMargin('-lg', '05',0.2rem);
    // 4px
    @include setPadding('-lg', '1', 0.4rem);              
    @include setMargin('-lg', '1',0.4rem);
    // 8px
    @include setPadding('-lg', '2', 0.6rem);              
    @include setMargin('-lg', '2', 0.6rem);
    // 16px
    @include setPadding('-lg', '3', 1.4rem);             
    @include setMargin('-lg', '3', 1.4rem);
    // 24px
    @include setPadding('-lg', '4', 2.4rem);              
    @include setMargin('-lg', '4', 2.4rem);
    // 48px
    @include setPadding('-lg', '5', 4.8rem);              
    @include setMargin('-lg','5', 4.8rem);
    // 72px
    @include setPadding('-lg', '6', 7.2rem);              // 16 * 4.5
    @include setMargin('-lg','6', 7.2rem);      
}
// ==== Xtra Large screen / extra wide desktop    
@media screen and (min-width: $screen-xl-min) {
    @include setPadding('-xl', '0', 0);
    @include setMargin('-xl', '0',0);
    // 2px
    @include setPadding('-xl', '05', 0.2rem);
    @include setMargin('-xl', '05',0.2rem);
    // 4px
    @include setPadding('-xl', '1', 0.4rem);              
    @include setMargin('-xl', '1',0.4rem);
    // 8px
    @include setPadding('-xl', '2', 0.6rem);              
    @include setMargin('-xl', '2', 0.6rem);
    // 16px
    @include setPadding('-xl', '3', 1.4rem);             
    @include setMargin('-xl', '3', 1.4rem);
    // 24px
    @include setPadding('-xl', '4', 2.4rem);              
    @include setMargin('-xl', '4', 2.4rem);
    // 48px
    @include setPadding('-xl', '5', 4.8rem);              
    @include setMargin('-xl','5', 4.8rem);
    // 72px
    @include setPadding('-xl', '6', 7.2rem);              // 16 * 4.5
    @include setMargin('-xl','6', 7.2rem);      
}

// Front page only 
// ---------------
    .template-portada_view {
        #content {
            margin-bottom: 0;
        }
    }