:root{
  accent-color: $brand-primary;
}

body {
	padding-top: 11rem;
	@media (min-width: $screen-sm-min) { // 768 pixels - 48em
		padding-top: 6rem;	
	}
}
// #fullMainContainer { overflow: hidden;}
#content { margin-bottom: 0; }
.layoutWrapper {
	@media (min-width: $screen-sm-min) { // 768 pixels - 48em
		display: flex;
		min-height: calc(100vh - 6.0rem);
		&::before, &::after {
			content: none;
		}
		.mapCol { flex: 1 1 auto;}
		.asideCol { width: 32.0rem;}
	}
	@media (min-width: $screen-md-min) { // 992 pixels - 62em
		.asideCol { width: 38.0rem;}
	}
}
#map {
	height: 24.0rem; width: 100%;
	@media (min-width: $screen-sm-min) { // 768 pixels - 48em
		height: 100%;
		position: fixed !important;
		left: 0; right: auto; top: 6rem;bottom: 0;
		width: calc(100% - 320px);
		// .plone-toolbar-left-expanded & {
		// 	left: 120px;
		// }
	}
	@media (min-width: $screen-md-min) { // 992 pixels - 62em
		width: calc(100% - 380px);	
	}
}

// .userrole-authenticated #main-container {
// 	margin-right: auto;margin-left: auto;
// 	padding-left: 15px; padding-right: 15px;
// 	@media (min-width: $screen-sm-min) { // 768 pixels - 48em
// 		width: $container-sm;
// 	}
// 	@media (min-width: $screen-md-min) { // 992 pixels - 62em
// 		width: $container-md;
// 	}
// 	@media (min-width: $screen-lg-min) { // 1200 pixels - 75em
// 		width: $container-lg;
// 	}
// }

// *****************************************
// Com funciona modal

.modal {
  display: none; /* Hidden by default */
  position: fixed; /* Stay in place */
  z-index: $zindex-modal; /* Sit on top */
  padding-top: 10.0rem; /* Location of the box */
  left: 0;
  top: 0;
  width: 100%; /* Full width */
  height: 100%; /* Full height */
  overflow: auto; /* Enable scroll if needed */
  background-color: rgb(0,0,0); /* Fallback color */
  background-color: rgba(0,0,0,0.4); /* Black w/ opacity */
}

/* Modal Content */
.modal-content {
  background-color: $white;
  margin: auto;
  padding: 20px;
  #help-text & {
	  border-radius: 0.25rem;
  }
}

// *****************************************
/* Header */
.content-header{
	&__wrapper {
		display: flex;
		flex-direction: column-reverse;
		align-items: center;
		.ml-auto {
			margin-left: auto;
		}
		@media (min-width: $screen-sm-min) { // 768 pixels - 48em
			display: flex;
			gap: 1rem;
			flex-direction: row;
		}
	}
}
.portalLogo {
	display: flex;
	align-items: center;
	padding: 1rem 0; 
	@media (min-width: $screen-sm-min) { // 768 pixels - 48em
		padding:0;
	}
	&__link {
		display: block;
		border-bottom: none;
		&:hover, &:focus {
			background-color: transparent;
			color: $text-color;
		}
	}
	&__image {
		max-width: 13rem;
	}
	&__title {
		border-left: 1px solid #ddd;
		font-size: $font-size-base;
		font-weight: 400;
		margin:0;
		@media (min-width: $screen-sm-min) { // 768 pixels - 48em
			font-size: $font-size-h5;
		}
	}
}


// *****************************************
// Informar d'incidencia
.incidenciesNav {
	display: flex;
	border-bottom: .5rem solid $brand-primary;
	& > * {
		flex-grow: 1;
	}
	&__button {
		border: 0;
		background-color: $white;
		color: $brand-primary;
		font-weight: $font-regular;
		text-align: center;
		padding: 2.5rem .1rem;
		&.active {
			font-weight: $font-bold;
			background-color: $brand-primary;
			color: $white;
		}
		&:hover, &:focus{
			text-decoration: underline;
		}
	}
}


.incidenciesInput {
	position: relative;
	margin-bottom: $font-size-base * 1.5;
	input[type="text"], input[type="password"], textarea, select, &__field {
		position: relative;
		z-index: 2;
		// border-width: 0 0 .1rem 0;
		// border-bottom-color: rgba($gray-light, 0.5);
		border: 1px solid rgba($gray-light, 0.5);
		border-radius: 0.25rem;
		font-size: $font-size-base;
		&:focus + label {
			transform: translateY(-100%);
			opacity: 1;
		}
		&.isWrong {
			border-bottom-color: $brand-danger;
		}
	}
	label {
		position: absolute;
		z-index: 1;
		top: 0;
		left: 0;
		transition: all $transition-fast ease-in;
		transform: translateY(100%);
		opacity: 0;
	}
	&--select {
		position: relative;
		&:after {
			content:'';
			width: 0;
			height: 0;
			border-style: solid;
			border-width: .8rem .6rem 0 .6rem;
			border-color: $text-color transparent transparent transparent;
			position: absolute; right: .75em; top: 50%; transform: translateY(-50%);
			z-index: $zindex-flat + 1;
		}
		select {
			-moz-appearance: none;
			appearance: none;
			padding-right: 1.5em;
			cursor: pointer;
		}
	}
}
.incidenciesTitle {
	display: flex;
	align-items: center;
	justify-content: flex-start;
	margin-bottom: 1em;
}
.nombreCircle {
	background-color: $brand-primary;
	color: $white;
	display: block;
	height: 4.8rem; width: 4.8rem;
	border-radius: 2.4rem;
	margin-right: 2.0rem;
	line-height: 4.8rem;
	text-align: center;
	flex-shrink: 0;
}
.getFoto {
	overflow: hidden;
	&__label {
		cursor: pointer;
		background-color: #162626;
		color: $white;
		font-size: $font-size-h5;
		border-radius: 4px;
		display: flex;
		&:hover, &:focus {
			text-decoration: underline;
		}
	}
	&__icon {
		padding: 1.4rem 1.0rem;
	}
	&__input{
		position: absolute;
		left: -1000em;
		opacity: 0;
	}
}

input[type="text"], input[type="password"], textarea, select{
	border-radius: 0.25rem;
	&:active, &:focus{
		border-color: $brand-primary;
		border-width: 2px;
	}
}

// *****************************************
// Llistat d'incidencies
.inFilterLabel {
	font-size: $font-size-h6;
	font-weight: $font-regular;
	padding: .2rem 1.4rem .2rem .3rem;
	color: $text-color;
	opacity: .8;
	display: block;
	border-radius: 1em;
	cursor: pointer;
	&.selected {
		opacity: 1;
	}
	&--comunicada.selected {
		background-color: $brand-danger;
		color: $white;
	}
	&--tramit.selected {
		background-color: $brand-warning;
		color: $text-color;
	}
	&--solucionada.selected {
		background-color: $brand-success;
		color: $white;
	}
	input[type="checkbox"] {
		position: absolute;
		left: -1000em;
	}
	&__check {
		margin: 0 .25em 0 .5em;
		&:before {
			font-family: "pictello";
			font-style: normal;
			font-weight: normal;
			display: inline-block;
			content: '\f096';
			.selected & {
				content: '\e807';
			}
		}
	}
}
.incidenceItem {		
	&__icon {
		font-family: "pictello";
		font-style: normal;
		font-weight: normal;
		display: block;
		position: absolute !important;
		top: 0.7rem; left: .4rem;
	}
	&__figure {
		width: 12.0rem; height: 12.0rem;
		flex: 0 0 12.0rem;
		overflow: hidden;
		@supports (object-fit: cover){
			img {
				object-fit: cover;
				width: 100%;height: 100%;
				max-width: none;
			}
		}
	}
	&__title {
		min-height: 2.5em;
		flex: 1 1 auto;
	}
	&__date {
		color: $gray;
	}
	&__type {
		text-align: left;		
		span {
			display: block;
			font-size: 1.2rem;
			opacity: .66;
		}
	}
}
.new-incidence a.detail-button {
	.incidenceItem__icon {
		color: $brand-danger;
	}
	&:hover, &:focus {
		color: $white;
		.incidenceItem__date, .incidenceItem__icon {
			color: $white;
		}
	}
	&::after {
		background-color: $brand-danger;
	}
}
.solved-incidence a.detail-button {
	.incidenceItem__icon {
		color: $brand-success;
	}
	&:hover, &:focus {
		color: $text-color;
		.incidenceItem__date, .incidenceItem__icon {
			color: $text-color;
		}
	}
	&::after {
		background-color: $brand-success;
	}
}
.processed-incidence a.detail-button {
	.incidenceItem__icon {
		color: $brand-warning;
	}
	&:hover, &:focus {
		color: $white;
		.incidenceItem__date, .incidenceItem__icon {
			color: $white;
		}
	}
	&::after {
		background-color: $brand-warning;
	}
}
a.detail-button {	
	color: $text-color;
	display: block;
	padding-left: 3.3rem;
	position: relative;
	border-bottom: none;
	&:hover, &:focus {
		text-decoration: none;
		background-color: transparent;
		&::after {
			width: 100%;
		}
	}
	&::after {
		content: '';
		display: block;
		position: absolute;
		left: 0;top: 0;bottom: 0;width: .3rem;
		z-index: $zindex-flat;
		transition: width $transition-slow ease-in-out;
	}
	& > * {
		position: relative;
		z-index: $zindex-flat + 1;
	}
}

.template-login_form #content {
	margin-top: 5rem;
}

.text-disabled {
	color: #8b8b8b
}

.incidences-date-filter{
	padding: 0.5rem !important;
	background-color: $brand-secondary;
	color: $white;
	border-radius: 0.25rem;
	&.selected {
		background-color: $brand-primary;	
	}

	&:hover, &:focus{
		text-decoration: underline;
		color: $white;
		background-color: $brand-secondary;
	}
}

.searchLoader {
	font-size: 6rem;
}

#content {
	padding: 5rem;
}

.template-incidences-map #content {
	padding: 0 !important;
}

/*OVERRIDES*/
.bg-gray-lighter{
	background-color: #f6f5f5;
}


/* response-info-content */
#response-info-content ul {
    padding-left: 0;
}
.response-info-content-buttons {
    gap: 3rem;
    justify-content: center;
    flex-wrap: wrap;
}
.modal_options_information {
	width: 100%;
	li {
		cursor: pointer;
		padding: 0.5rem 1.5rem;
		background-color: #fff;
		&:hover {
			background-color: #f6f5f5;
		}
	}
}