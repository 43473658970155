// Transparency
// --------------------------
.opacity {
  &-0 {
    @include opacity(0);
  }
  &-33 {
    @include opacity(.33);
  }
  &-50 {
    @include opacity(.5);
  }
  &-66 {
    @include opacity(.66);
  }
  &-100 {
    @include opacity(1);
  }
}

.show-for-sr {
  border: 0;
  clip: rect(0 0 0 0);
  height: 1px;
  margin: -1px;
  overflow: hidden;
  padding: 0;
  position: absolute;
  width: 1px;
}

// Animation
// --------------------------
@keyframes bouncing {
  0%, 100% {
    transform: translateY(0);
  }
  50% {
    transform: translateY(0.5em);
  }
}
.icoBouncing:hover {
  [class^="picto"], [class*=' picto'] {
    animation: bouncing .5s;
    animation-iteration-count: infinite;
  }
}

// Shadow
.myShadow {
  box-shadow: 0 .3rem .6rem rgba(0, 0, 0, 0.16);
  
}