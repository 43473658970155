//
// Scaffolding
// --------------------------------------------------

  html {
    font-size: 62.5%;
  }

// Only display content to screen readers
//
// See: http://a11yproject.com/posts/how-to-hide-content

  .hiddenStructure,
  .crud-form .header-select {
    @extend .sr-only;
    left: -10000rem;    
  }