//
// Responsive: Utility classes
// --------------------------------------------------

	.visible-block,
	.display-block {
		display: block !important;
	}
	.visible-inline,
	.display-inline {
		display: inline !important;
	}
	.visible-inline-block,
	.display-inline-block {
		display: inline-block !important;
		vertical-align: top;
	}
	.visible-none,
	.display-none {
		display: none !important;
	}